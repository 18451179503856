function setAccount(account) {
  return "<button class=\"accounts-tree-button\" data-account-id=\"" + account.id + "\" data-account-children=\"" + account.has_children + "\">"
    + "<span class=\"las la-wallet\"></span>" + account.account_label
    + "</button>";
}

function setAccountWithChildren(account) {
  return "<button class=\"collapse-header accounts-tree-button\" data-target=\"#id-" + account.id + "-collapse\" data-toggle=\"collapse\" data-account-id=\"" + account.id + "\" data-account-children=\"" + account.has_children + "\">"
    + "<span class=\"collapse-indicator la la-arrow-circle-down\"></span>" + "<span class=\"las la-file-invoice-dollar\"></span>" + account.account_label
    + "</button>"
    + "<div class=\"collapse\" id=\"id-" + account.id + "-collapse\"></div>";
}

function setAlertMessage(wasEdit, success) {
  return `
        <div class="w-full alert alert_outlined alert_${success ? 'success' : 'danger'}">
            <strong class="uppercase"><bdi>${success ? 'Success' : 'Error'}!</bdi></strong>
            Account ${wasEdit ? 'update' : 'creation'} ${success ? 'was successful' : 'failed'}.
            <button class="dismiss la la-times" data-dismiss="alert"></button>
        </div>
        `;
}

function fetchChildren(accountId) {
  $.ajax({
    url: '/accounts?account_id=' + accountId,
    type: "GET",
    dataType: 'json',
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
    },
    success: function (response) {
      let children = ''
      if (response.accounts) {
        response.accounts.forEach(function (account) {
          children = children + (account.is_nested ? setAccountWithChildren(account) : setAccount(account));
        });
      }
      const depth = response.self.depth || 0;
      if (depth < 5) {
        children = children + "<button class=\"accounts-tree-button\" data-parent-id=\"" + accountId + "\">" +
          "<span class=\"las la-plus\"></span> New Account"
          + "</button>";
      }
      $("#id-" + accountId + "-collapse").html(children);
    }
  });
}


function fetchSelf(self_account) {
  $.ajax({
    url: '/accounts?account_id=' + self_account.id,
    type: 'GET',
    dataType: 'json',
    error: function (xhr, status, error) {
      console.error('AJAX Error: ' + status + error);
    },
    success: function (response) {
      let html = self_account.is_nested ? setAccountWithChildren(self_account) : setAccount(self_account);
      $('.tree').find('.add-btn').before(html);
    }
  });
}

$(document).on('click', '.accounts-tree-button', function () {
  const el = $(this)
  const accountId = el.attr('data-account-id');
  $('.accounts-tree-button').not('.collapse-header').removeClass('active');
  //console.log(accountId,this);
  if (accountId) {
    // Fetches if account is nested and collapse is opening
    if (el.hasClass('collapse-header') && el.hasClass('active')) fetchChildren(accountId);
    else el.addClass('active');

    // Checks if last open form is of the same account
    const lastFormUrl = $("#form-container form").attr('action')
    let lastId = null;
    if (lastFormUrl) {
      const chunks = lastFormUrl.split('/');
      lastId = chunks[chunks.length - 1];
    }
    if (lastId == accountId) return;

    $.ajax({
      url: '/accounts/' + accountId + '/edit',
      type: "GET",
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (response) {
        if (response) $("#form-container").html(response);
      }
    });
  }
  else {
    const parentId = el.attr('data-parent-id');
    $.ajax({
      url: '/accounts/new' + (parentId ? ('?account_id=' + parentId) : ''),
      type: "GET",
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (response) {
        if (response) $("#form-container").html(response);
        if (parentId) fetchChildren(parentId);
      }
    });
  }
});

$(document).on('submit', '#account-form', function (event) {
  event.preventDefault();

  const url = $(this).attr('action');
  // console.log(url);
  $.ajax({
    url: url,
    type: $(this).attr('method'),
    data: $(this).serialize(),
    success: function (response) {
      // $('#account-form').get(0).reset();

      $("#form-container").html(
        setAlertMessage(url !== '/accounts', true)
      );
      if (!response) return;

      if (response.parent_id) {

        fetchChildren(response.parent_id);
      } else if (response.self_account) {
        fetchSelf(response.self_account);
      }
    },
    error: function (xhr, status, error) {
      console.error("AJAX Error: " + status + error);
      $("#form-container").html(
        setAlertMessage(url !== '/accounts', false)
      );
    }
  });
});

$(document).on('submit', '#reports-form', function (event) {
  event.preventDefault();
  // console.log($(this).serialize());
  const element = document.getElementById('report-download-link');
  if (element) {
    element.href = $(this).attr('action') + '.xlsx?' + $(this).serialize();
    element.click();
    setTimeout(function () {
      const element = document.getElementById('reports-modal-close-button');
      if (element) element.click();
    }, 1000);

  }
  return false;
});

function getParsedDate(date) {
  let month = (date.getMonth() + 1);
  if (month < 10) month = '0' + month;
  let day = date.getDate();
  if (day < 10) day = '0' + day;

  return date.getFullYear() + '-' + month + '-' + day;
}

$(document).on("change", "#report", function () {
  const value = $(this).val();
  // console.log(value);
  const depth_field = $('#depth');
  const disable = value != 0;
  depth_field.prop("readonly", disable);
  if (disable) depth_field.parent().addClass('hidden');
  else depth_field.parent().removeClass('hidden');

  const enableAccountNumber = value == 2;
  const el = $('#report-account-id').parent().parent();
  if (!el) return;
  if (enableAccountNumber) el.removeClass('hidden');
  else el.addClass('hidden');

  // const dailyJournalSelected = value == 5;
  // if (dailyJournalSelected) {
  //   $("#from_date_label").html("Date");
  //   $("#to_date").parent().addClass('hidden');
  //   $("#from_date").val(getParsedDate(new Date()));
  // }
  // else {
  //   $("#from_date_label").html("From Date");
  //   $("#to_date").parent().removeClass('hidden');
  //   $("#from_date").val(getParsedDate(new Date(new Date().getFullYear(), 0, 1)));
  // }
});

/**
   *
   * @param {JQuery<HTMLElement>} el
   */
function reset_type_ahead(el, reset = true, text = 'Please Type To Filter Results') {
  el.val('');
  const input = el.next();
  if (reset) input.val('');
  input.next().empty().append(`<div class="px-4 placeholder"> ${text} </div>`);
}

function search_accounts(filter) {
  // console.log(filter);
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/accounts/search',
      type: 'GET',
      data: { filter: filter, report: $('#report').val() },
      success: function (response) {
        resolve(response);
      },
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
        reject(error);
      }
    });
  });
}

function populate_type_ahead(el, list) {
  el.empty();
  for (let index = 0; index < list.length; index++) {
    const item = list[index];
    el.append(`<div class="w-full px-4 py-2 type_ahead_select_item" data-value="${item.id}"> ${item.select_name} </div>`);
  }
};

function printAccountList(accounts = []) {
  const listEl = $('#accounts-select-list');
  if (accounts.length < 1) {
    listEl.empty().html('<p class="p-5 text-lg font-bold"> No Accounts Found!</p>')
    return;
  }
  let content = '';
  accounts.forEach((ac) => {
    content += `
    <div class="accounts-search-select-list-item" account-path="${ac.ancestory}">
      <div class="heading">
        <div class="name">${ac.name}</div>
        <div class="currency">(${ac.currency})</div>
      </div>
      <div class="sub-text">${ac.ancestors}</div>
    </div>
    `;
  });
  listEl.empty().html(content);
}

function scrollToElement($element, $container) {

  if ($element.length && $container.length) {
    const containerHeight = $container.height();
    const elementOffsetTop = $element.position().top; // Position relative to the container
    const elementHeight = $element.outerHeight();

    // Calculate the scroll position to center the element
    const scrollPosition = elementOffsetTop - (containerHeight / 2) + (elementHeight / 2);

    // Animate the scroll
    $container.animate(
      { scrollTop: scrollPosition },
      500 // Duration in milliseconds
    );
  }
}

function goToAccount(accountPath = []) {
  let count = 0;               // Initialize the counter
  const maxRuns = accountPath.length - 1;           // Number of times the interval should run
  const intervalTime = 1000;   // Interval time in milliseconds

  if (accountPath.length < 0) return;

  $('#spinner-modal-trigger').trigger('click');

  const intervalId = setInterval(function () {
    // console.log(`Interval run: ${count}`);
    console.log(accountPath[count]);
    const treeButton = $(`.accounts-tree-button[data-account-id="${accountPath[count]}"]`);
    // console.log(treeButton.next(), !treeButton.hasClass('active'));
    const collapse = treeButton.next('.collapse');
    if (!collapse) {
      treeButton.addClass('active');
      treeButton.trigger('click');
    }
    else if (!collapse.hasClass('open')) {
      treeButton.addClass('active');
      treeButton.trigger('click');
    }

    // Stop the interval after maxRuns
    if (count >= maxRuns) {
      clearInterval(intervalId); // Stop the interval
      // console.log("Interval stopped");
      $('#spinner-modal button').trigger('click');
      scrollToElement(treeButton, $('.accounts-tree-container'));
    }
    count++;                   // Increment the counter
  }, intervalTime);
}


$(() => {
  const el = $('#report-account-id');
  el.next().on('input', function () {
    const input = $(this);
    const value = input.val();
    if (!value || value.length < 3) {
      reset_type_ahead(el, false, 'No Accounts Found');
      return;
    }

    // return;
    search_accounts(value)
      .then((resp) => {
        if (!resp || resp.length < 1) {
          reset_type_ahead(el, false, 'No Accounts Found');
          return;
        }
        const select_list = input.next();
        populate_type_ahead(select_list, resp);
        const acc = resp.find((ac) => ac.select_name == value.trim());
        if (acc) {
          el.val(acc.id).trigger('change');
          input.next().removeClass('active');
        };
      }).catch(err => {
        console.error(err)
      });
    // if (class_ref.branches.length < 1) {
    //   reset_type_ahead(el, false, 'No Accounts Found');
    //   return;
    // }
    // const input = $(this);
    // const select_input = input.prev();
    // select_input.val('');
    // const val = input.val().toLowerCase();
    // if (val.length < 2) {
    //   reset_type_ahead(el, false);
    //   return;
    // }
    // const filtered_branches = branches.filter((b) => b.name.toLowerCase().includes(val));
    // const select_list = input.next();
    // select_list.children('.placeholder').toggle(filtered_branches.length < 1);
    // if (filtered_branches.length < 1) reset_type_ahead(el, false, 'No Branches Found');
    // else populate_type_ahead(select_list, filtered_branches);
  });

  const $type_ahead_input = $('.type_ahead_input');
  $type_ahead_input.on('focus', function () {
    const input = $(this);
    // input.prev().val('');
    input.next().addClass('active');
  });
  $type_ahead_input.on('blur', function () {
    const input = $(this);
    setTimeout(() => {
      input.next().removeClass('active');
    }, 300);
  });

  $('.type_ahead_select_list').on('click', function (event) {
    const item = $(event.target);
    if (!item.hasClass('type_ahead_select_item')) return;
    const val = item.attr('data-value');

    const parent = item.parent().parent();
    parent.children('.type_ahead_input').val(item.text().trim());
    parent.children('.type_ahead_select_list').empty().append('<div class="px-4 placeholder"> Please Type To Filter Results </div>');
    parent.children('.type_ahead_select_value').val(val).trigger('change');
  });

  $('#search-form').on('submit', function (e) {
    $('#spinner-modal-trigger').trigger('click');
    const listEl = $('#accounts-select-list');
    listEl.addClass('hidden');
    e.preventDefault(); // Prevent the default form submission


    const $form = $(this);
    const $button = $form.find('input[type="submit"]'); // Select the search button
    $button.prop('disabled', true);
    const query = $form.find('input[name="query"]').val();

    // Make an AJAX request
    $.ajax({
      url: $(this).attr('action'),
      method: 'GET',
      dataType: 'json', // Expect JSON response
      data: { query: query },
      success: function (response) {
        // console.log(response)
        printAccountList(response.accounts);
        // Handle the JSON response
        // let resultsHtml = '';
        // if (response.results.length > 0) {
        //   resultsHtml = '<ul>';
        //   response.results.forEach(function (result) {
        //     resultsHtml += `<li>${result.name}</li>`;
        //   });
        //   resultsHtml += '</ul>';
        // } else {
        //   resultsHtml = '<p>No results found.</p>';
        // }
        // $('#results').html(resultsHtml);
      },
      error: function (xhr) {
        // $('#results').html('<p>An error occurred while processing your request.</p>');
        printAccountList();
      },
      complete: function () {
        $('#spinner-modal button').trigger('click');
        listEl.removeClass('hidden');
        $button.prop('disabled', false); // Re-enable the button after the request finishes
      }
    });
  });

  $(document).on('click', '.accounts-search-select-list-item', function () {
    const path = $(this).attr('account-path');
    const pathList = path.split('/')
    pathList.shift();
    // console.log(pathList);
    goToAccount(pathList);
  });

  $('.coa-container').on('click', function () {
    $('#accounts-select-list').addClass('hidden');
  })
});
